z<template>
  <v-container>
    <p class="headline font-weight-bold">
      {{lang=='id'? 'Kemudahan Berusaha Propinsi Jatim' : 'Business Ease in East Java Province'}}
    </p>
    <p>
      {{lang==='id'?'Kemudahan Berusaha di Jawa Timur adalah upaya Pemerintah Provinsi Jawa Timur dalam penyediaan fasilitas non fiskal dari Pemerintah Daerah kepada Masyarakat dan/atau Investor untuk mempermudah setiap kegiatan investasi dan untuk meningkatkan investasi di daerah. Pemberian kemudahan berusaha di Jawa Timur telah diatur dalam Peraturan Daerah Jawa Timur Nomor 2 Tahun 2019 tentang Penanaman Modal.' : 'Ease of Doing Business in East Java is the effort of the East Java Provincial Government in providing non-fiscal facilities from the Local Government to the Community and/or Investors to facilitate any investment activities and to increase investment in the region. The provision of ease of doing business in East Java is regulated by the East Java Regional Regulation Number 2 of 2019 concerning Investment.'}}
    </p>


    <v-flex class="subtitle-1 font-weight-bold text-center mt-10">
      {{lang==='id'?'Bagian Kedua' : 'Second Part'}}
    </v-flex>
    <v-flex class="subtitle-1 font-weight-bold text-center">
      {{lang==='id'?'Bentuk Insentif dan Kemudahan' : 'Forms of Incentives and Facilities.'}}
    </v-flex>
    <v-flex class="subtitle-1 text-center mt-2 mb-6">
      {{lang==='id'?'Pasal 24' : 'Article 24'}}
    </v-flex>

    <div v-if="lang==='id'">
      <ol class="my-2">
        <li>Pemberian insentif sebagaimana dimaksud dalam Pasal 23 dapat berbentuk:</li>
        <ol type="a">
          <li>Pengurangan atau keringanan pajak daerah dan/ atau
            retribusi daerah;</li>
          <li>Pemberian dana stimulan; dan/ atau</li>
          <li>Pemberian bantuan modal.</li>
        </ol>
        <li>Pemberian kemudahan sebagaimana dimaksud dalam Pasal 23 dapat berbentuk:</li>
        <ol type="a">
          <li>penyediaan data dan informasi peluang penanaman
            modal;</li>
          <li>Penyediaan sarana dan prasarana;</li>
          <li>Penyediaan lahan atau lokasi:</li>
          <li>Bimbingan pelaksanaan pelaporan kegiatan penanaman modal;</li>
          <li>Fasilitasi dan koordinasi penjajagan penanaman modal dengan pihak terkait;</li>
          <li>Menjalin kerja sama dalam rangka persiapan penanaman modal;</li>
          <li>Percepatan pemberian perizinan;</li>
          <li>Fasilitasi ketenagakerjaan dan hubungan industrial;
            fasilitasi dan koordinasi penyelesaian masalah
            pelaksanaan penanaman modal; dan/ atau</li>
          <li>Fasilitasi penyediaan energi.</li>
        </ol>
      </ol>
    </div>
    <div v-if="lang==='en'">
      <ol class="my-2">
        <li>The provision of incentives as referred to in Article 23 can take the form of:</li>
        <ol type="a">
          <li>Reduction or exemption of local taxes and/or local levies;</li>
          <li>Provision of stimulus funds; and/or</li>
          <li>Provision of capital assistance.</li>
        </ol>
        <li>The provision of facilities as referred to in Article 23 can take the form of:</li>
        <ol type="a">
          <li>Provision of data and information on investment opportunities;</li>
          <li>Provision of facilities and infrastructure;</li>
          <li>Provision of land or location:</li>
          <li>Guidance on the implementation of investment activity reporting;</li>
          <li>Facilitation and coordination of investment exploration with related parties;</li>
          <li>Establishing cooperation in preparation for investment;</li>
          <li>Accelerating the granting of permits;</li>
          <li>Facilitation of labor and industrial relations;
            facilitation and coordination of problem-solving
            in the implementation of investment; and/or</li>
          <li>Facilitation of energy provision.</li>
        </ol>
      </ol>
    </div>

  </v-container>

</template>

<script>
export default {
  name: "KemudahanBerusaha",
  watch: {
    lang: {
      handler: function (val, oldVal) {
        if (val !== oldVal){
          // this.fetchFKomoditi()
        }
      }
    },
  },
  computed: {
    lang:{
      get(){
        return this.$store.state.langModule.lang
      },
      set(val) {
        this.$store.dispatch('langModule/setLang', val)
      }
    },
  },
}
</script>

<style scoped>

</style>