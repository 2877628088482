<template>
  <v-container>
    <p class="headline font-weight-bold">
      {{lang==='id'? "Fasilitas Impor" : 'Import Facilities'}}
    </p>
    <p>
      {{lang==='id'?"Fasilitas pembebasan bea masuk impor dapat diberikan kepada Pelaku Usaha yang melakukan kegiatan usaha di bidang industri yang menghasilkan barang dan/atau jasa (Peraturan Menteri Keuangan Nomor 176/PMK.011/2009 jo. Nomor 188/PMK.010/2015), industri pembangkitan listrik (Peraturan Menteri Keuangan Nomor 66/PMK.010/2015), serta Kontrak Karya/PKP2B (Peraturan Menteri Keuangan Nomor 116/PMK.04/2019)." : "Facilities in the form of import entry may be granted to Business Actors who carry out business activities in industries that produce goods and/or services (Regulation of the Minister of Finance Number 176/PMK.011/2009 jo. Number 188/PMK.010/2015), power generation industries ( Minister of Finance Regulation Number 66/PMK.010/2015), and Contract of Work/PKP2B (Minister of Finance Regulation Number 116/PMK.04/2019)"}}
    </p>
    <v-img :src="lang==='id'? 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/3/fasilitas impor1.jpg' : 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/3/fasilitas%20impor1_en.jpg'"></v-img>
    <v-img :src="lang==='id'? 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/3/fasilitas impor2.jpg' : 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/3/fasilitas%20impor2%20En.jpg'"></v-img>
    <v-img :src="lang==='id'? 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/3/fasilitas impor3.jpg' : 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/3/fasilitas%20impor3%20En.jpg'"></v-img>
    <v-img :src="lang==='id'? 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/3/fasilitas impor4.jpg' : 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/3/fasilitas%20impor4%20en.jpg'"></v-img>
    <v-img :src="lang==='id'? 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/3/fasilitas impor5.jpg' : 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/3/fasilitas%20impor5%20en.jpg'"></v-img>
    <v-img :src="lang==='id'? 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/3/fasilitas impor6.jpg' : 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/3/fasilitas%20impor6%20en.jpg'"></v-img>
    <v-img :src="lang==='id'? 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/3/fasilitas impor7.jpg' : 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/3/fasilitas%20impor7%20en.jpg'"></v-img>
  </v-container>

</template>

<script>
export default {
  name: "FasilitasImport",
  watch: {
    lang: {
      handler: function (val, oldVal) {
        if (val !== oldVal){
          // this.fetchFKomoditi()
        }
      }
    },
  },
  computed: {
    lang:{
      get(){
        return this.$store.state.langModule.lang
      },
      set(val) {
        this.$store.dispatch('langModule/setLang', val)
      }
    },
  },
}
</script>

<style scoped>

</style>