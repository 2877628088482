<template>
  <v-container>
    <p class="headline font-weight-bold">
      Tax Allowances
    </p>
    <p>
     {{ lang==='id'? "Berdasarkan Peraturan Pemerintah Nomor 78 Tahun 2019 dan Peraturan Menteri Keuangan Republik Indonesia Nomor 11/PMK.010/2020 jo. Peraturan Menteri Keuangan Republik Indonesia Nomor 96/PMK.010/2020, Pelaku Usaha yang melakukan Penanaman Modal pada Kegiatan Usaha Utama sebagaimana tercantum dalam Lampiran Peraturan Pemerintah Nomor 78 Tahun 2019; dan memenuhi kriteria dan persyaratan tertentu, dapat diberikan fasilitas Pajak Penghasilan. Bidang Usaha pada sektor Industri juga merujuk Peraturan Menteri Perindustrian Republik Indonesia Nomor 47 Tahun 2019." : "Based on Government Regulation Number 78 of 2019 and Regulation of the Minister of Finance of the Republic of Indonesia Number 11/PMK.010/2020 jo. Regulation of the Minister of Finance of the Republic of Indonesia Number 96/PMK.010/2020, Business Actors conducting Investment in Main Business Activities as listed in the Attachment to Government Regulation Number 78 of 2019; and meets certain criteria and requirements, can be given Income Tax facilities. The Business Sector in the Industrial sector also refers to the Regulation of the Minister of Industry of the Republic of Indonesia Number 47 of 2019."}}
    </p>
    <v-img :src="lang==='id'?'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/1/tax allowance_1.jpg' : 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/1/tax%20allowance%20En.jpg'"></v-img>
    <v-img :src="lang==='id'? 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/1/alur proses tax allowance.jpg' : 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/1/alur%20proses%20tax%20allowance_en.jpg'"></v-img>
    <v-img :src="lang==='id'? 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/1/tax allowance holiday KEK.jpg' : 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/1/tax%20allowance%20KEK%20En.jpg'"></v-img>
    <v-img :src="lang==='id'? 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/1/tax allowance KEK.jpg' : 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/1/alur%20proses%20tax%20allowance%20KEK%20en.jpg'"></v-img>
  </v-container>
</template>

<script>
export default {
  name: "TaxAllowance",
  watch: {
    lang: {
      handler: function (val, oldVal) {
        if (val !== oldVal){
          // this.fetchFKomoditi()
        }
      }
    },
  },
  computed: {
    lang:{
      get(){
        return this.$store.state.langModule.lang
      },
      set(val) {
        this.$store.dispatch('langModule/setLang', val)
      }
    },
  },
}
</script>

<style scoped>

</style>