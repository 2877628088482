<template>
  <v-container>
    <p class="headline font-weight-bold">
      Tax Holiday
    </p>
    <p>
      {{lang==='id'? "Berdasarkan Peraturan Menteri Keuangan Republik Indonesia Nomor 130/PMK.010/2020 tentang Pemberian Fasilitas Pengurangan Pajak Penghasilan Badan dan Peraturan Badan Koordinasi Penanaman Modal Nomor 7 Tahun 2020 tentang Rincian Bidang Usaha Dan Jenis Produksi Industri Pionir Serta Tata Cara Pemberian Fasilitas Pengurangan Pajak Penghasilan Badan, Wajib Pajak badan yang melakukan penanaman modal baru pada Industri Pionir dapat memperoleh pengurangan Pajak Penghasilan badan atas penghasilan yang diterima atau diperoleh dari Kegiatan Usaha Utama yang dilakukan." : "Based on the Regulation of the Minister of Finance of the Republic of Indonesia Number 130/PMK.010/2020 concerning Provision of Corporate Income Tax Reduction Facilities and Investment Coordinating Board Regulation Number 7 of 2020 concerning Details of Business Fields and Types of Production of Pioneer Industries and Procedures for Providing Corporate Income Tax Reduction Facilities, Corporate Taxpayers who make new investments in Pioneer Industries may obtain corporate Income Tax deductions on income received or earned from the Main Business Activities carried out."}}
    </p>

    <v-img :src="lang==='id'? 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/9/tax holiday indo_1.jpg' : 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/9/tax%20holiday%20En_1.jpg'"></v-img>
    <v-img :src="lang==='id'? 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/9/tax holiday indo_2.jpg' : 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/9/tax%20holiday%20En_2.jpg'"></v-img>
    <v-img :src="lang==='id'? 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/9/tax holiday indo_3.jpg' : 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/9/tax%20holiday%20En_3.jpg'"></v-img>
    <v-img :src="lang==='id'? 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/9/tax holiday indo_4.jpg' : 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/9/tax%20holiday%20En_4.jpg'"></v-img>
    <v-img :src="lang==='id'? 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/9/tax holiday indo_5.jpg' : 'https://regionalinvestment.bkpm.go.id/admin//upload/jenis_insentif/9/tax%20holiday%20En_5.jpg'"></v-img>

  </v-container>

</template>

<script>
export default {
  name: "TaxHoliday",
  watch: {
    lang: {
      handler: function (val, oldVal) {
        if (val !== oldVal){
          // this.fetchFKomoditi()
        }
      }
    },
  },
  computed: {
    lang:{
      get(){
        return this.$store.state.langModule.lang
      },
      set(val) {
        this.$store.dispatch('langModule/setLang', val)
      }
    },
  },
}
</script>

<style scoped>

</style>