<template>
  <v-flex class="ma-2">
    <v-card width="100%" min-height=500px>
      <v-tabs
          v-model="tabSelected"
          color="deep-purple accent-4"
          centered
      >
        <v-tab key="TaxAllowances">Tax Allowances</v-tab>
        <v-tab key="FasilitasImport">{{lang==='id'? 'Fasilitas Import' : 'Import Facilities'}}</v-tab>
        <v-tab key="SuperDeduction">Super Deduction</v-tab>
        <v-tab key="TaxHoliday">Tax Holiday</v-tab>
        <v-tab key="KemudahanBerusaha">{{lang==='id'? 'Kemudahan Berusaha Prov Jatim' : 'Business Ease in East Java Province'}}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabSelected">
        <v-tab-item key="TaxAllowances">
          <TaxAllowance></TaxAllowance>
        </v-tab-item>
        <v-tab-item key="FasilitasImport">
          <FasilitasImport></FasilitasImport>
        </v-tab-item>
        <v-tab-item key="SuperDeduction">
          <SuperDeduction></SuperDeduction>
        </v-tab-item>
        <v-tab-item key="TaxHoliday">
          <TaxHoliday></TaxHoliday>
        </v-tab-item>
        <v-tab-item key="KemudahanBerusaha">
          <KemudahanBerusaha></KemudahanBerusaha>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <PublicFooter></PublicFooter>

 </v-flex>

</template>

<script>
import PublicFooter from "@/components/PublicFooter";
import TaxAllowance from "@/components/public_insentif/insentif_tabitems/TaxAllowance";
import FasilitasImport from "@/components/public_insentif/insentif_tabitems/FasilitasImport";
import SuperDeduction from "@/components/public_insentif/insentif_tabitems/SuperDeduction";
import KemudahanBerusaha from "@/components/public_insentif/insentif_tabitems/KemudahanBerusaha";
import TaxHoliday from "@/components/public_insentif/insentif_tabitems/TaxHoliday";

export default {
  name: 'InsentifMain',
  components: {
    TaxHoliday,
    KemudahanBerusaha,
    SuperDeduction,
    FasilitasImport,
    TaxAllowance,
    PublicFooter
  },
  data(){
    return {
      tabSelected: '',
    }
  },
  watch: {
    lang: {
      handler: function (val, oldVal) {
        if (val !== oldVal){
          // this.fetchFKomoditi()
        }
      }
    },
  },
  computed: {
    lang:{
      get(){
        return this.$store.state.langModule.lang
      },
      set(val) {
        this.$store.dispatch('langModule/setLang', val)
      }
    },
  },
}
</script>

<style scoped>


</style>