<template>
  <InsentifMain></InsentifMain>
</template>

<script>
import InsentifMain from "../../components/public_insentif/InsentifMain";
export default {
  name: "InsentifVie",
  components:{
    InsentifMain
  }
}
</script>

<style scoped>

</style>